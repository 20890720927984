import React from "react";

import { Footer3 } from "../components/Footers";
import { Hero2Dense } from "../components/Heros";
import Head from "../head";

// markup
const IndexPage = () => {
  return (
    <main>
      <Head title="Privacy policy" />
      <Hero2Dense />

      <section className="bg-slate-light py-12">
        <div className=" max-w-default m-auto leading-9">
          <h1 className="text-desktop-h2 mb-4">Privacy Policy</h1>
          <p>
            We are Urlcheckr. We respect your privacy and private life, but
            sometimes we need your personal data. In this privacy policy we
            explain which data we use and how we save, protect and process this
            data. This privacy policy applies to our website
            https://urlcheckr.com (the “Website), our online application (the
            “App”) and the services that we offer (the “Services”). We comply
            with the General Data Protection Regulation (the “Relevant
            Legislation”).
          </p>

          <h4 className="text-xl mt-8 mb-2">
            Are you under the age of sixteen?
          </h4>

          <p>
            If you are younger than sixteen years old, you cannot use our
            Website, App and Services without the permission of your parents or
            legal guardian.
          </p>

          <h4 className="text-xl mt-8 mb-2">Personal data</h4>

          <p>
            To offer our Website, App and Services we process personal data.
            “Personal data” means any information relating to an identified or
            identifiable natural person as defined in the Relevant Legislation.
          </p>

          <h4 className="text-xl mt-8 mb-2">Your permission</h4>

          <p>
            We can store your data at various moments, for example when you
            visit our Website, create an account via our Website or App, use our
            Services or when you contact us. We are allowed to process your data
            because we ask your permission via this privacy policy. In addition,
            we need your data to carry out the agreement between you and us. If
            you choose not to share your data, thenan account cannot be made and
            you cannot use our software.
            <br />
            <br />
            We will not process your data without your consent, unless we are
            legally obliged to do so.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            What data do we collect and how do we use your data?
          </h4>

          <p>
            To use our Website, App and Services, we need the following data:
          </p>

          <ul className="list-disc list-inside pl-4">
            <li>Your name</li>
            <li>Your e-mail address</li>
            <li>Your IP-address</li>
          </ul>

          <p>
            We can also store the following non-personal data if you use our
            Website or App:
          </p>

          <ul className="list-disc list-inside pl-4">
            <li>The type of your browser</li>
            <li>The operating system that you use</li>
          </ul>

          <h4 className="text-xl mt-8 mb-2">Login</h4>

          <p>
            We save the following data to your account: Password, username,
            email address, first and last name, company name. This way, you
            don’t need to provide us with your data again.
          </p>

          <h4 className="text-xl mt-8 mb-2">Market research</h4>

          <p>
            We may ask you to participate in market research. We shall use your
            data for the market research. We use that statistical data
            anonymously for Urlcheckr. You can close the question list at any
            moment. We do not share your answers with others or make them
            publicly available. In addition, your answers are not connected to
            your e-mail address.
          </p>

          <h4 className="text-xl mt-8 mb-2">Newsletter</h4>

          <p>
            Urlcheckr offers newsletters. That way, you are fully informed of
            (discount)offers and other news. We use an double opt-in system to
            be sure of your permission. This means that you verify your
            permission for any new confirmation. We ask you for this
            verification via an e-mail that we send to the address you have
            given us. Every time we send you a newsletter you have the
            possibility to unsubscribe from the newsletter.
          </p>

          <p>
            We will only use your personal data for the purposes listed above or
            any other purpose that is connected thereto. This way, your data
            will never be used by us in an unexpected way.
          </p>

          <h4 className="text-xl mt-8 mb-2">Security</h4>

          <p>
            We work hard to protect your personal data from unauthorized or
            unlawful access, alteration, disclosure, use or destruction. That
            way, unauthorized persons do not have access to your data. We take
            the following measures to protect your personal data:
          </p>

          <ul className="list-disc list-inside pl-4">
            <li>
              Secure network connections with Secure Socket Layer (SSL)
              technology or a technology that is similar to SSL
            </li>
            <li>
              The access to the data is limited to the persons that need the
              data
            </li>
          </ul>

          <h4 className="text-xl mt-8 mb-2">How long do we store your data?</h4>

          <p>
            We shall store your personal data for as long as necessary to fulfil
            the purposes listed above.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            With whom do we share your personal data?
          </h4>

          <h4 className="text-xl mt-8 mb-2">Processors</h4>

          <p>
            We may share your personal data with others. In a data processing
            agreement, we shall agree with those parties that they shall use
            your data carefully and they shall only receive the data that is
            relevant for their services. These parties use your data in
            accordance with our instructions and not for their own purposes. We
            can share your data for example with Paddle.com. They will take care
            of handling the payments for our subscriptions. These parties are
            “processors” within the meaning of the Relevant Legislation.
          </p>

          <h4 className="text-xl mt-8 mb-2">Third parties</h4>

          <p>
            Sometimes we share your data with third parties who are not
            processors. We will agree with those parties that they will use your
            data carefully. They will only use your data in accordance for the
            purposes that we received your data for. We may share your data for
            example with: partner companies.
          </p>

          <h4 className="text-xl mt-8 mb-2">Transfer</h4>

          <p>
            We will only process your data within the European Union. We will
            only process your data outside of the European Union if the country
            has an effective protection level for your data.
            <br />
            <br />
            We will never transfer your data to other countries or other parties
            then described above.
          </p>

          <h4 className="text-xl mt-8 mb-2">Cookies</h4>

          <p>
            Cookies are little (text)files which are stored on your computer.
            Your web browser stores these cookies when you visit our Website.
            These cookies will be retrieved when you visit our website again.
            This allows our Website to recognise you as a previous visitor.
            <br />
            <br />
            We may use cookies to improve your user experience on our Website.
            Cookies are essential for the operation of our Website, make sure
            that you can visit our Website safely and track bugs and errors at
            our Website.
          </p>

          <p>
            You can change your cookie settings in your browser, if you don’t
            want cookies to be sent to your device. We will not save cookies if
            you visit our Website. Please note that some Website features or
            services of our Website may not function properly without cookies.
          </p>

          <h4 className="text-xl mt-8 mb-2">
            Modifications to this privacy policy
          </h4>

          <p>
            We may update our privacy policy from time to time. When we change
            this privacy policy in a significant way, we will post a
            notification on our Website and our App along with the updated
            privacy policy. We also inform users that are registered with an
            email address in case of any significate moderation. If you are not
            registered as user we advise you to visit the Website and this
            policy regularly.
          </p>

          <h4 className="text-xl mt-8 mb-2">Your rights</h4>

          <p>
            The data we collect is personal. Therefore, you have the following
            rights:
          </p>

          <ul className="list-disc list-inside pl-4">
            <li>
              You may request access to the personal data we process about you;
            </li>
            <li>
              You may request us to correct, update, shield or delete your
              personal information in our records;
            </li>
            <li>
              You may request a copy of the personal data we have processed
              about you. We can – on your request – send this copy to another
              party, so you don’t have to send the data yourself;
            </li>
            <li>You may file a complaint against processing your data;</li>
            <li>
              You may file a complaint with the Dutch Data Protection
              Authority(Autoriteit Persoonsgegevens), if you are under the
              impression that we process your data unlawfully;
            </li>
            <li>
              You may always withdraw your consent to process your data. We
              cannot process your data from the moment you withdraw your
              consent.
            </li>
          </ul>

          <p>
            Should you have further questions regarding this privacy policy,
            please contact us via the information below.
          </p>

          <h4 className="text-xl mt-8 mb-2">Contact information</h4>

          <p>
            Device Independent
            <br />
            Speenkruid 11
            <br />
            8935JS Leeuwarden
            <br />
            KvK-number: 60574615
            <br />
            support@urlcheckr.com
          </p>
        </div>
      </section>

      <Footer3 />
    </main>
  );
};

export default IndexPage;
